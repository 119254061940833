
import ValidationMixin from "@/mixins/validation";
import {
  CloseTicketDetails,
  TicketTypes,
} from "@/store/modules/ticket/ticket.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const ticketX = namespace("Ticket");

@Component({
  components: {},
})
export default class CloseTicket extends Mixins(ValidationMixin) {
  @ticketX.State(TicketTypes.CLOSE_TICKET_STATE)
  public closeTicketState!: DefaultState;

  @ticketX.Mutation(TicketTypes.SET_CLOSE_TICKET_DIALOG)
  public setCloseTicket!: (closeTicket: boolean) => void;

  @ticketX.Action(TicketTypes.CLOSE_TICKET)
  public closeTicket!: (farmer: CloseTicketDetails) => Promise<void>;

  public closeTicketStep = 1;

  public isValid = false;

  public closeDetails: CloseTicketDetails = {
    resolution: "",
    rootCause: "",
  };

  @Ref("form") public formRef!: Validator;

  closeCloseTicketDialog(): void {
    this.setCloseTicket(false);
  }

  async saveTicketToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.closeTicket(this.closeDetails);
    }
  }
}
